.card-list-array-container {

  .slick-prev,
  .slick-next {
    cursor: pointer;
    position: fixed;
    background-color: transparent;
    border-color: transparent;
    color: transparent;
  }

  .slick-prev {
    left: 3vw;
    bottom: 22vh;
  }

  .slick-next {
    left: 75.35vw;
    bottom: 22vh;
  }

  &.all {
    .slick-slider {
      margin: auto !important;

      .slick-prev,
      .slick-next {
        top: unset;
        margin: auto;
        bottom: 15px;
        z-index: 9;
        border-top: 22px solid transparent;
        border-bottom: 22px solid transparent;
      }

      .slick-prev {
        left: 0;
        right: 50px;
        border-right: 13px solid black !important;
      }

      .slick-next {
        left: 50px;
        right: 0;
        border-left: 13px solid black !important;
      }
    }

    .slick-slide {
      width: 15vw !important;
    }
  }

  .slick-slider {
    max-width: 70%;
    width: 70%;
    overflow-x: hidden;
    overflow-y: visible;

    .slick-list {
      overflow: hidden;
    }

    .slick-prev {
      border-right: 20px solid black;
    }

    .slick-next {
      border-left: 20px solid black;
    }

    .slick-prev,
    .slick-next {
      width: 0;
      height: 0;
      border-top: 30px solid transparent;
      border-bottom: 30px solid transparent;
    }

    .slick-prev:before,
    .slick-next:before {
      display: none;
    }
  }

  &.portrait {

    .slick-prev,
    .slick-next {
      position: absolute;
    }

    .slick-next {
      left: unset;
      right: 3vw;
    }

    .slick-slider {
      margin: auto;
    }
  }
}

.slick-track {
  display: flex;
}

.slick-dots {
  height: 2.6vh;
  justify-content: center;
  display: flex !important;
  list-style: none;
  padding: 0 !important;

  li button {
    height: 2.6vh;
    width: 2.6vh;
    margin: 0.325vh;
    border: white solid 2px;
    color: transparent;
    background-color: transparent;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
  }

  li.slick-active {
    button {
      background-color: white;
    }
  }
}

.background-array {
  .slick-dots {
    bottom: 55vh !important;
  }
}

.team-page {
  .slick-prev {
    border-right: 20px solid #1c1c1c !important;
  }

  .slick-next {
    border-left: 20px solid #1c1c1c !important;
  }
}