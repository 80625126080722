.positioned-text-box {
  width: fit-content;
  position: absolute;
  margin-left: 5vw;
  z-index: 2;

  .text-input-container {
    width: 50%;

    .text-input {
      margin-top: 1vh;
      width: 100%;
      padding: 10px;
      border-radius: 2vh;

      &.search {
        font-family: 'FontAwesome', $font-regular;
      }
    }
  }

  &.portrait {
    margin: unset;
    position: unset;
    width: 100%;
    padding-top: 5vh;

    .text-input-container {
      margin: auto;

      .text-input {
        width: 100%;
      }
    }

    .title,
    .description {
      width: fit-content;
      max-width: 85%;
      margin: auto;
      overflow-wrap: break-word;
    }

    .flat-button-container {
      text-align: center;
    }
  }
}