.category-page {
  width: 100%;
  height: 100%;
  background-color: white;
  color: black;
  position: absolute;

  .positioned-text-box {
    .more {
      background-color: $accent-color;
    }
  }

  &.portrait {
    overflow-y: scroll;
    overflow-x: hidden;

    .slick-slider,
    .slick-slide {
      max-width: 70vw !important;
      width: 70vw !important;
    }
  }
}

.main-category-page {
  position: absolute;
  overflow: scroll;

  .text-input-container {
    .text-input {
      margin-top: 1vh;
      width: 50%;
      padding: 10px;
      border-radius: 2vh;

      &.search {
        font-family: 'FontAwesome', $font-regular;
        border: 0;
      }
    }
  }

  &.portrait {
    .slick-slider,
    .slick-slide {
      max-width: 70vw !important;
      width: 70vw !important;
    }
  }

  .button-array-container {
    &.portrait {
      padding-bottom: 100px;
    }
  }
}