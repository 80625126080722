.main-page {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: scroll;

  .menu-card-array-container {
    position: fixed;
    min-height: 100vh;
    right: 1rem;
    justify-content: center;
    align-items: flex-end;

    .menu-card {
      width: 40vh;
      height: 14vh;
      margin: 1vh 0;

      .menu-card-image {
        width: 25%;
        height: 100%;

        &.left {
          border-radius: 3vw 0 0 3vw;
          float: left;
        }

        &.right {
          border-radius: 0 3vw 3vw 0;
          float: right;
        }
      }

      .menu-card-text-container {
        font-family: $font-light;
        background-color: black;
        width: 75%;
        height: 100%;
        display: table;

        .menu-card-text-box {
          display: table-cell;
          vertical-align: middle;
          max-width: 23vh;
          overflow: hidden;
          color: white;

          &.left {
            padding: 0 2vh 0 3vh;
          }

          &.right {
            padding: 0 3vh 0 2vh;
          }

          .title {
            font-size: 4vh;
            line-height: 3.5vh;
            padding-bottom: 0.2rem;
          }

          .description {
            font-size: 1.2vh;
            line-height: 100%;
          }
        }

        &.left {
          border-radius: 3vw 0 0 3vw;
          text-align: right;
          float: left;
        }

        &.right {
          border-radius: 0 3vw 3vw 0;
          float: right;
        }
      }
    }

    &.portrait {
      min-height: fit-content;
      right: unset;
      justify-content: center;
      align-items: center;
      position: absolute;
      padding-bottom: 100px;

      .menu-card {
        width: 85%;
        height: 20vh;

        .menu-card-image {
          &.right {
            border-radius: 0 80px 80px 0 !important;
          }

          &.left {
            border-radius: 80px 0 0 80px !important;
          }
        }

        .menu-card-text-container {
          &.right {
            border-radius: 0 80px 80px 0 !important;
          }

          &.left {
            border-radius: 80px 0 0 80px !important;
          }
        }

        .title {
          font-size: 4vh !important;
          line-height: 4vh !important;
        }

        .description {
          font-size: 2vh !important;
        }
      }
    }
  }
}