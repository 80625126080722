.logout-button,
.refresh-button,
.home-button {
  width: 12vw;
  left: 2vw;
  height: auto;
  position: absolute;
  z-index: 5;
}

.refresh-button,
.home-button {
  top: 2vw;
}

.refresh-button {
  width: 1.5vw;
  padding: 0.5vw;
  left: 4vw !important;
  background-color: black;
  border-radius: 50%;
}

@media (prefers-reduced-motion: no-preference) {

  .sending,
  .refreshing {
    animation: refreshing-spin infinite 2s linear;
  }
}

@keyframes refreshing-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.logout-button {
  left: 1.5vw;
  bottom: 4vh;
  width: 1.9vw;
}

.home-button {
  z-index: 150;

  &.portrait {
    width: 24vw;
  }
}

.flat-button {
  margin: 0.6rem 0.2rem;
  width: 16vw;
  height: 4.5vw;
  padding: 1vw;
  border-radius: 5vh;
  border-width: 0px;
  color: white;
  background-color: $accent-color;
  font-weight: bold;
  font-size: 0.9vw;

  &:hover {
    background-color: gray;
  }

  &.small {
    width: 12vw !important;
    height: 4vw !important;
  }

  &.more {
    width: unset !important;
    height: unset !important;
    padding: .55vw 1.65vw !important;
  }

  &.portrait {
    font-size: 3vw !important;
    padding: 1.55vw 2.65vw !important;
    height: 7vh;
  }
}

.back-button {
  padding: 0.4vw 2.2vw;
  font-size: 20px;
  bottom: 4vh;
  margin-left: 5vw;
  position: absolute;
  z-index: 9;
  width: unset;
  height: unset;

  &.portrait {
    font-size: 3vw!important;
    padding: 1.55vw 2.65vw!important;
    height: unset;
  }
}