@font-face {
  font-family: "Montserrat Thin";
  src: local("Montserrat Thin"), url("../fonts/Montserrat-Thin.ttf");
  font-display: fallback;
}

@font-face {
  font-family: "Montserrat Light";
  src: local("Montserrat Light"), url("../fonts/Montserrat-Light.ttf");
  font-display: fallback;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"), url("../fonts/Montserrat-Regular.ttf");
  font-display: fallback;
}

@font-face {
  font-family: "Montserrat Bold";
  src: local("Montserrat Bold"), url("../fonts/Montserrat-Bold.ttf");
  font-display: fallback;
}

@font-face {
  font-family: "Montserrat Black";
  src: local("Montserrat Black"), url("../fonts/Montserrat-Black.ttf");
  font-display: fallback;
}

$font-thin: "Montserrat Thin";
$font-light: "Montserrat Light";
$font-regular: "Montserrat";
$font-bold: "Montserrat Bold";
$font-black: "Montserrat Black";

$primary-color: #45748f;
$accent-color: #1c1c1c;
$font-color: #808080;