.scrollbar-box-portrait,
.scrollbar-box {
  font-size: 2vh;
  margin: 16vh 5.2vw;
  margin-right: 0.5rem;
  padding-right: 1rem;
  height: 55vh;
  overflow-y: scroll;
  overflow-x: hidden;

  .info-table {
    margin-top: 2vh;

    .row {
      padding: 4px 7px;

      &.row-0 {
        background-color: $accent-color;
      }

      .name {
        text-align: left;
      }

      .value {
        text-align: right;
      }
    }
  }

  .bullets-list {
    margin-top: 2vh;
    line-height: 2.5vh;
  }

  .product-details-card {
    padding-top: 0.2rem;
    font-size: 1.5vh;

    .title-box {
      margin-bottom: 0.4rem;
      padding-top: 1.5vh;

      .title {
        font-size: 7vh;
        line-height: 7vh;
      }

      .description {
        line-height: 2.5vh;
      }
    }

    .water-type-box {
      display: flex;

      .water-type-logo {
        width: 3vh;
        height: 3vh;
        margin-right: 1vh;
        background-size: unset;
      }
    }

    .price-box {
      margin-top: 1vh;
      display: flex;

      .price {
        background-color: $accent-color;
        margin-right: 1vw;
        padding: 0.3vw 3vw 0.3vw 0.5vw;
      }
    }

    .paragraph-box {
      line-height: 2.3vh;

      .paragraph {
        margin-top: 2.5vh;
      }
    }

    .logo {
      height: 5vh;
      width: auto;
      margin: 1.5vh 1vh 1vh 0;
    }
  }

  .language-labels {
    margin-top: 2vh;
  }

  .consumer-labels-title {
    margin-top: 2vh;
    display: block;
  }

  .consumer-labels-array {
    .consumer-labels-item {
      background-size: contain !important;
      width: 2.6vw;
      height: 2.6vw;
      margin-right: 10px;
      z-index: 3;
    }
  }
}
.scrollbar-box-portrait {
  margin: 3vh;
  height: fit-content;
  overflow-y: unset;
}

.scrollbar-box::-webkit-scrollbar {
  display: unset !important;
}