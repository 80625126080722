.product-page {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  color: white;
  overflow-y: scroll;

  .scrollable {
    display: unset !important;
    height: inherit !important;
    padding-top: 10px !important;


    &::-webkit-scrollbar {
      display: none;
    }
  }

  .footer-box {
    position: fixed;
    height: 15% !important;
    width: 100%;
    bottom: 0rem;
  }

  .image-bg {
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 0.3;

    &.continent {
      opacity: 1 !important;
    }
  }

  .page-content {
    position: fixed;

    .album-card {
      width: 100%;
      height: 55vh;
      margin-top: 16vh;

      .brand-logo {
        position: fixed;
        width: 5.2vw;
        height: 5.2vw;
        margin-left: 1vw;
        background-size: contain;
        z-index: 99999;
      }

      .grid-row {
        margin-bottom: 5px;
      }

      .image-album {
        background-size: contain;
        height: 43vh;
        width: 100%;
        margin-left: 1vw;
      }
    }
  }

  .art-nr {
    margin-bottom: 0.5rem;
  }

  .flat-button-array-container,
  .property-flat-button-array-container {
    z-index: 2;
    justify-content: flex-start;
    display: flex;

    &.colors-box {
      .color-ball {
        width: 3vh;
        height: 3vh;
        border-radius: 50%;
        margin: 0.5vh 1vh 0 0;
      }
    }
  }

  .tab-button-array-container {
    position: fixed;
    margin-left: 5vw;
    z-index: 2;
    bottom: calc(15% - 1px);
    width: 30% !important;

    .tab-button {
      width: 7vw;
      padding: 1.5vh 1.2vw 0.5vh 1vw;
      border-radius: 0.8vw 0.8vw 0 0;
      border-width: 0px;
      font-weight: bold;
      font-size: 0.65vw;
      color: white;
    }
  }

  .album {
    overflow: hidden;
  }

  .download-button {
    bottom: 15%;
    position: relative;
    padding: 0.6vw 1.65vw;
    margin-left: 5.2vw;
    background-color: $accent-color;
    font-size: unset;
    font-family: $font-regular !important;
    width: unset;
    height: unset;
  }

  .slick-dots {
    margin-top: 20vh !important;
  }

  &.portrait {
    .content {
      overflow: hidden;

      .slick-dots {
        padding: unset;
        margin-top: 0vh !important;
      }
    }

    .album-card {
      width: 100%;
      height: 55vh;

      .image-album {
        height: 50vh;
        width: 70%;
        margin: 3vh auto 0 auto;
        background-size: contain;
      }
    }

    .scrollbar-box-portrait {
      padding-right: unset !important;
    }

    .footer-box {
      z-index: 8;
    }

    .button-array-container {
      padding-bottom: 50%;
    }

    .tab-button-array-container {
      width: 100% !important;
      z-index: 10;

      .tab-button {
        width: 45%;
        font-size: 2vh;
        border-radius: 2vh 2vh 0 0;
        z-index: 10;
        opacity: 1 !important;

        &.not-selected {
          background-color: #555 !important;
        }
      }
    }

    .download-button-container {
      width: 100%;
      text-align: center;

      .download-button {
        margin-left: unset;
      }
    }
  }
}