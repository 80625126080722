.about-page {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .slick-dots {
    bottom: 15vh !important;
    position: relative;
  }

  .values-text {
    text-align: center;
    font-size: 7vh;
    line-height: 6vh;
    top: 10vh;
    position: relative;

    .title {
      font-family: $font-light;
    }

    strong {
      font-family: $font-bold;
      color: black;
    }

    .description {
      font-size: 4vh;
      line-height: 4vh;
    }
  }

  .page-text {
    position: absolute;
    width: 18vw;
    bottom: 15vh;
    left: 4vw;
    color: black;
  }

  .vision-figure {
    position: fixed;
  }

  .circle-container {
    position: absolute;
    width: 100%;
    z-index: 3;

    .circle-bg {
      background-color: white;
      position: relative;
      margin: 10vh auto;
      border-radius: 50%;
      width: 81vh !important;
      height: 80vh;
      background-color: white;
    }
  }

  .circle-face {
    position: absolute;
    width: 20vh;
    height: 20vh;
    border-radius: 50%;

    .description {
      font-size: 1.5vh;
      padding: 1.2vh;
      text-align: center;
      top: 10vh;
      transform: translateY(-50%);
      position: absolute;
      width: -webkit-fill-available;
    }
  }

  .circle {
    position: absolute;
    width: 20vh;
    height: 20vh;

    &.circle-0 {
      top: -20vh;
      left: 15vh;
    }

    &.circle-1 {
      top: -11.5vh;
      right: -8vh;
    }

    &.circle-2 {
      top: 9vh;
      right: -19.5vh;
    }

    &.circle-3 {
      top: 32.5vh;
      right: -15.5vh;
    }

    &.circle-4 {
      top: 48vh;
      right: 3vh;
    }

    &.circle-5 {
      top: 48vh;
      left: 3vh;
    }

    &.circle-6 {
      top: 32.5vh;
      left: -15.5vh;
    }

    &.circle-7 {
      top: 9vh;
      left: -19.5vh;
    }

    &.circle-8 {
      top: -11.5vh;
      left: -8vh;
    }
  }

  .middle {
    position: relative;
    width: 50vh !important;
    height: 50vh;
    margin: 25vh auto;
  }

  .team-page {
    .card-item {
      font-size: 1.3vh;
    }
  }

  &.portrait {
    .team-page {
      overflow-y: scroll;
      overflow-x: hidden;
      position: absolute;

      .card-list-array-container {

        .card-item {
          height: 52vh;
          font-size: 2vh;

          &.team {
            .card-image {
              height: 36vh;
            }
          }
        }

        &.all {
          margin: 0;
          top: 0;
          padding-bottom: 0vh !important;
          height: 100%;


          .slick-slider,
          .slick-slide {
            max-width: 80vw !important;
            width: 80vw !important;
          }

          .card-item {
            margin-top: 5vh;
            height: 38vh;
            width: 70vw;
            font-size: 3vw;

            &.team {
              .card-image {
                height: 28vh;
              }
            }
          }

          .slick-dots {
            bottom: 0vh !important;
            padding-left: 0 !important;
          }
        }
      }
    }

    .positioned-text-box {
      position: relative !important;
    }

    .button-array-container {
      margin-top: 8vh;
    }

    .background-array {
      position: absolute;
      width: 100%;
      height: 100%;

      .slick-dots {
        padding: 0;
      }
    }

    .icon-page {
      position: absolute;

      .circle-container {
        transform: scale(0.49);
      }
    }

    .circle-container {
      .middle {
        margin: 3vh auto;
      }

      .circle {
        &.flipped {
          top: -15vh;
          left: auto;
          right: auto;
          position: relative;
          margin: auto;
          z-index: 99;

          .back {
            transform: scale(4) !important;
          }
        }
      }
    }
  }
}