html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none
}

* {
  outline: none;

  font-family: $font-light;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  overflow: hidden;
}

strong,
b {
  font-family: $font-bold;

  &.small-bold {
    font-family: $font-regular !important;
  }

  &.black {
    font-family: $font-black !important;
  }
}

.blue {
  color: $primary-color;
}

div.root {
  height: 100%;
}

.bg-props {
  width: 100vw !important;
  height: 100vh !important;
  max-width: 100% !important;
}

.bg-props,
.logo-props {
  background-repeat: no-repeat !important;
  background-size: cover;
  background-position: center;
}

.pointer {
  cursor: pointer;
  z-index: 5;
}

.MuiButton-contained {
  box-shadow: none !important;
  -webkit-box-shadow: none;
  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: none;
}

.button-array-container.selectable.MuiGrid-container {
  padding-top: 10px;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.button-array-container.selectable.MuiGrid-container::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  width: 14px;
  height: 18px;
  display: none;
}

::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  background-color: #e6e6e6;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

.empty-image {
  border: 2px gray dotted;
}

p {
  margin: unset;
}

.portrait {
  -webkit-overflow-scrolling: touch
}