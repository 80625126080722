.solution-page {
  position: absolute;
  width: 100%;
  height: 100%;
  color: $font-color;
  font-size: 0.75vw;
  overflow: scroll;

  .text-box {
    margin: 20vh auto 2vh auto;
    width: fit-content;
    color: white;
    font-size: 5vh;
  }

  .card-container,
  .water-selection-box,
  .multi-selection-box {
    width: fit-content;
    height: fit-content;
    margin: 2vh auto;
    display: flex;

    .selector {
      width: 12vw;
      position: relative;
    }
  }

  .water-selection-box {
    .selector {
      height: 15vh;
      text-align: center;

      .icon {
        width: 5vh;
        height: 5vh;
        margin: 2vh auto;
      }

      .checkbox {
        width: 2vh;
        position: absolute;
        bottom: 0;
        left: 5vw;
        height: 4.2vh;
      }
    }
  }

  .multi-selection-box {
    .selector {
      width: 15vw;
      height: 8vh;
      margin: 5vh 1vh 1vh 1vh;

      &.control {
        width: fit-content;
        height: unset;

        label {
          margin: 0.5vw;
        }
      }
    }

    &.options {

      .selector {
        height: fit-content;

        .label {
          padding-bottom: 6vh;
          display: block;
        }
      }
    }
  }

  .button-container {
    text-align: center;

    .flat-button {
      font-size: 1.5vw;
    }
  }

  .select-field {
    width: 15vw;
    height: 5vh;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 2vw;
  }

  .card-container {
    .selector {
      margin: 0.5vh 1vw;
      height: 45vh;

      .title {
        color: white;
      }

      .empty {
        margin: 1.25vw;
      }

      .select-field {
        width: 10vw !important;
      }

      .card-item {
        width: 12vw;
        height: 35vh;
        border: solid 1px;
        background: black;
        color: white;
        position: relative;
        margin: 0.5vh 0;

        .card-text-container {
          position: absolute;
          bottom: 0;
          width: 100%;

          .card-text-box {
            position: absolute;
            margin: 0.5vw;
            bottom: 0;
            width: 90%;
          }
        }

        .next-icon {
          background-color: gray;
          border-radius: 2vw;
          padding: 0 0.5vw;
          position: absolute;
          font-size: 2vw;
          right: -1vw;
          top: 28vh;
        }

        .card-image {
          width: 80%;
          height: 25vh;
          margin: calc(10% - 2px);
          background-size: contain;
        }
      }
    }
  }

  &.portrait {
    .text-box {
      margin: 10vh auto 2vh auto;
      text-align: center;
    }

    .water-selection-box {
      display: block;

      .selector {
        width: 40vw;
        height: fit-content;
        margin: 4vh;

        .label {
          font-size: 3vw;
        }

        .checkbox {
          right: 0;
          top: 2vh;
        }
      }
    }

    .multi-selection-box {
      display: block;
      font-size: 3vw;
      text-align: center;

      .selector {
        height: unset;
        width: 40vw;

        .select-field {
          width: 40vw;
        }

        &.control {
          display: block;

          input {
            width: 100%;
            margin: 1vh auto;
          }
        }
      }

      &.options {

        .select-field {
          border-radius: 2vh !important;
        }
      }
    }

    .card-container {
      display: block;
    }

    .button-container {
      .flat-button {
        &.portrait {
          width: 40vw;
          margin-bottom: 100px !important;
        }
      }
    }

    .card-container {
      .selector {
        font-size: 3vw !important;
        width: 40vw;

        .card-item {
          width: 40vw;
        }

        .select-field {
          width: 40vw !important;
          margin: 3vh 0;
        }

        &.lengthy {
          height: 50vh;
        }
      }

      .next-icon {
        font-size: 5vw !important;
        padding: 0.5vw 2vw !important;
        right: -4vw !important;
        border-radius: 4vw !important;
      }
    }
  }
}