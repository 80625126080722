.card-list-array-container {
  position: absolute;
  height: 40vh;
  width: 100%;
  bottom: 10vh;
  margin: 6.5vh 10vw 3.5vh 5vw;
  z-index: 2;
  overflow: visible;

  .description {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 85%;
  }

  &.all {
    height: 100%;
    width: 100%;
    margin: unset;
    bottom: 0.5vh !important;

    .brand-logo {
      position: relative !important;
      top: 5px !important;
    }
  }

  .card-item {
    width: 95%;
    height: 39vh;
    border: solid 1px;
    background: black;
    color: white;
    margin: auto;
    position: relative;
    margin-top: 3vh;

    .card-text-container {
      bottom: 0vh;
      position: absolute;

      .card-text-box {
        margin-left: 0.5vw;
        bottom: 1vh;
        position: absolute;
        padding: 1vh;
      }
    }

    .card-image {
      width: 80%;
      height: 28vh;
      margin: calc(10% - 2px);
      background-size: contain;
    }

    .new-label {
      position: absolute;
      top: 0.2rem;
      background: yellow;
      color: black;
      font-weight: bold;
      font-size: 20px;
      padding: 8px;
      right: -0.5vw;
    }

    .brand-logo {
      position: fixed;
      z-index: 4;
      top: 10px;
      margin-left: 15px;
      width: 2.6vw;
      height: 2.6vw;
      background-size: contain;
    }

    &.team {
      border-color: $accent-color;

      .card-image {
        width: 100%;
        height: 80%;
        margin: unset;
        background-size: cover;
      }

      .card-text-box {
        position: relative;
        width: 90%;
        margin: 10px auto;
      }

      &.backside {
        .divider {
          border-bottom: 1px white solid;
          margin: 1vh 0;
        }
      }

    }
  }

  &.portrait {
    height: fit-content;
    right: unset;
    margin: 3vh auto;
    bottom: unset;
    padding-bottom: 100px;
    position: relative;

    .card-item {
      .card-image {
        height: 20vh;
      }
    }
  }
}

.background-array {
  .text-container {
    top: -45vh;
    left: 75vw;
    position: relative;
    height: 40vh;
    width: 20vw;

    .text {
      bottom: 0;
      right: 0;
      position: absolute;
    }
  }

  &.portrait {
    position: absolute;
    top: 0;
  }
}