.gallery-page {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .slick-dots {
    position: relative;
    padding: unset;
    margin-left: auto;
    bottom: 50vh !important;
  }

  &.portrait {
    .background-array {
      position: unset !important;
      top: unset !important;

      .text {
        bottom: 5vh;
        width: 50vw;
      }
    }
  }
}