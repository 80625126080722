.intro-page {
  width: 100%;
  height: 100%;
  position: absolute;
  text-align: center;
  text-align: -webkit-center;
  background-color: black;
  color: white;
  font-size: 2vw;

  .title {
    margin-top: 30vh;
    font-size: 7vw;
  }

  .logo-flag {
    width: 6vw;
    height: 3vw;
    margin: 6vh 0.5vw;
  }

  .footer {
    color: $font-color;
    font-size: 16px;
    position: sticky;
    text-align: center;
    top: 93%;
  }

  &.portrait {
    .title {
      margin-top: 20vh;
      font-size: 14vw;
    }

    .description {
      font-size: 5vw;
    }

    .logo-flag {
      width: 20vw;
      height: 10vw;
    }
  }
}