.pdf-main-page {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: scroll;
  top: 0;
  z-index: 99;

  .pdf-page {
    top: -55px;
  }

  &.portrait {
    position: absolute;
    .pdf-container {
      width: 100%;
      height: 100%;
      transform: scale(0.4);
      transform-origin: top left;
      margin-top: 10vh;
      margin-left: calc(50% - 42mm);
      position: relative;

      .pdf-page {
        margin: 0;
      }

      .send-button-container {
        margin-top: -15%;
        width: max-content;
        margin-left: 50%;

        .flat-button {
          width: 60vw !important;
          padding: 3vh 10vw !important;
          font-size: 10vw !important;
          width: unset;
          height: unset;
          z-index: 111;
        }
      }
    }
  }
}

.solution-page {
  .pdf-main-page {
    &.portrait {
      top: 100%;
      height: fit-content;
    }
  }
}

.pdf-container {
  width: fit-content;
  margin: auto;
}

.exit-button {
  color: white;
  font-size: 2vh;
  position: absolute;
  left: calc(50% + 110mm);
  background-color: gray;
  border-radius: 3vh;
  padding: 0.5vh 1vh;
  top: 0;
}

.send-button-container {
  position: relative;
  margin-top: -80px;
  margin-bottom: 70px;
  z-index: 111;
  text-align: center;

  .flat-button {
    &.send {
      width: 10vw;
      height: 7vh;
      font-size: 1.5vw;
      z-index: 111;
    }
  }
}