.flat-button-array-container {
  justify-content: center;
  align-items: center;
  bottom: 5vh;
  width: auto !important;
}

.button-array-container {
  position: absolute;
  justify-content: center;
  width: fit-content !important;
  height: 100vh;
  right: 1rem;
  z-index: 5;

  .flat-button {
    font-size: 1vw;
    margin: 0.5vh;
  }

  &.portrait {
    height: fit-content;
    right: unset;
    left: unset !important;
    margin: 4vh auto 0 auto;
    position: relative;

    .flat-button {
      font-size: 2vw;
      width: 40vw;

      &.small {
        width: 30vw !important;
        height: 5vh !important;
      }
    }
  }
}
