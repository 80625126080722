.sender-dialog {
  width: 50vw;
  height: 70vh;
  position: fixed;
  top: calc(50% - 35vh);
  left: calc(50% - 25vw);
  background: $accent-color;
  z-index: 11;
  border-radius: 4vh;

  .image-container {
    margin: 2vh auto;
  }

  .flat-button {
    &.send {
      width: 10vw;
      height: 7vh;
      font-size: 1.5vw;
      z-index: 111;
      background-color: $font-color;
      left: calc(50% - 5vw);
      bottom: 2vh;
      position: absolute;
    }
  }

  .logo-container {
    background-color: black;
    border-radius: 50%;
    width: 10vw;
    height: 10vw;
    position: absolute;
    padding: 5vw;
    left: calc(50% - 10vw);

    .sending-logo {
      width: 10vw;
      z-index: 666;
      height: fit-content;
    }
  }

  .text-input-container {
    width: 80%;
    margin: auto;

    .text-input {
      margin-top: 1vh;
      width: calc(100% - 20px);
      padding: 10px;
      border-radius: 2vh;

      &.message {
        height: 12vh;
      }
    }
  }
}

.pdf-main-page.portrait {
  .sender-dialog {
    width: 90vw;
    height: fit-content;
    position: fixed;
    top: 10vh;
    left: calc(50% - 45vw);
    background: $accent-color;
    z-index: 11;
    border-radius: 4vh;
    padding: 6vh 0;

    .image-container {
      margin: unset;
    }

    .flat-button {
      width: 20vw;
      height: unset;
      font-size: 4vw !important;
      z-index: 111;
      background-color: $font-color;
      left: calc(50% - 10vw);
      bottom: -3vh;
      position: absolute;
    }
  }
}